export const pcMenu = [
  {
    name: "首页",
    path: "/home",
  },
  {
    name: "人艺概览",
    path: "/renyiOverView",
  },
  {
    name: "人艺剧场",
    path: "/renyiTheatre",
    children: [
      // {
      //   name: "北京人艺演出中心",
      //   path: "/renyiTheatre/introduction",
      // },
      // {
      //   name: "组织机构",
      //   path: "/renyiOverView/organization",
      // },
      {
        name: "首都剧场",
        path: "/renyiTheatre/capitalTheatre",
      },
      {
        name: "北京人艺实验剧场",
        path: "1",
      },
      {
        name: "北京国际戏剧中心",
        path: "/renyiTheatre/theatreCenter",
      },
      {
        name: "曹禺剧场",
        path: "2",
      },
      {
        name: "人艺小剧场",
        path: "3",
      },
      {
        name: "菊隐剧场",
        path: "/renyiTheatre/juYinTheatre",
      },
      // {
      //   name: "北京人艺发展基金会",
      //   path: "/renyiTheatre/foundation",
      // },
      // {
      //   name: "北京人艺戏剧书店",
      //   path: "/renyiTheatre/createCenter",
      // },
    ],
  },
  {
    name: "人艺演出",
    path: "/performance/index",
    children: [
      {
        name: "演出购票",
        path: "http://piao.bjry.com/",
      },
      {
        name: "正在演出",
        path: "/performance/index",
      },
    ],
  },
  {
    name: "人艺资讯",
    path: "/ArtsInformation",
    children: [
      {
        name: "剧院动态",
        path: "/ArtsInformation/dynamic",
      },
      {
        name: "剧院公告",
        path: "/ArtsInformation/announcement",
      },
      {
        name: "剧目推介",
        path: "/ArtsInformation/recommend",
      },
      {
        name: "人艺活动",
        path: "/ArtsInformation/activity",
      },
      // {
      //   name: "星光大合影",
      //   path: "/ArtsInformation/groupPhoto",
      // },
    ],
  },
  {
    name: "人艺人",
    path: "/PeopleEntertainers",
    children: [
      {
        name: "创始人",
        path: "/PeopleEntertainers/founder",
      },
      {
        name: "编剧",
        path: "/PeopleEntertainers/screenwriter",
      },
      {
        name: "导演",
        path: "/PeopleEntertainers/director",
      },
      {
        name: "设计",
        path: "/PeopleEntertainers/devise",
      },
      {
        name: "演员",
        path: "/PeopleEntertainers/actor",
      },
      {
        name: "舞美",
        path: "/PeopleEntertainers/stage",
      },
    ],
  },
  {
    name: "人艺文化",
    path: "/culture",
    children: [
      {
        name: "剧目典藏",
        path: "/culture/collection/index",
      },
      {
        name: "人艺视界",
        path: "/culture/video/index",
      },
      {
        name: "悦读人艺",
        path: "/culture/periodical/index",
      },
      {
        name: "人艺文创",
        path: "/culture/creativity/index",
      },
      {
        name: "观剧指南",
        path: "/culture/guide/index",
      },
    ],
  },
  {
    name: "戏剧博物馆",
    path: "",
    children: [
      {
        name: "戏剧博物馆",
        path: "/theatreMuseum",
      },
    ],
  },
];
